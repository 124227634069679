<template>
<!-- 所有页面底部copyright标识 -->
  <div>
    <footer class="bg-dark text-center text-light lh-lg shadow">
        <div class="nav flex-column justify-content-center">Copyright © 2022 GJZ</div>
    </footer>
  </div>
</template>

<script>
const options = {};
export default options;
</script>

<style scoped>
footer{
  height: 2.5rem;
}

footer div {
  height: 100%;
}
</style>
